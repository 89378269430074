<template>
    <el-container class="page-container">
        <el-header class="page-header" style="min-height: 60px;height: auto">
            <el-form label-position='left' ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="12" :lg="6">
                        <el-form-item prop="type" label="单位" label-width="80px">
                            <el-cascader class="inner-input" :props="props" v-model="parentUnitSearch"
                                         placeholder="请选择单位"></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="12" :lg="6">
                        <el-form-item prop="status">
                            <el-input placeholder="请输入内容" class="inner-input" v-model="pageForm.searchKeyword"
                                      @keyup.enter.native="doSearch">
                                <el-select v-model="pageForm.searchName" slot="prepend" placeholder="查询类型"
                                           style="width: 120px;">
                                    <el-option label="角色名称" value="name"></el-option>
                                    <el-option label="角色标识" value="code"></el-option>
                                </el-select>
                                <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="12" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="doSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <!--<el-button type="primary" plain>-->
                                <!--<i class="cw-icon cw-icon-zhongzhi"></i>重置-->
                            <!--</el-button>-->
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="12" :lg="6"
                            style="display: flex;justify-content: flex-end">
                        <el-button type="primary" @click="openAdd"><i class="ti-plus"></i> 新建角色</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <el-main class="page-main" style="padding: 0 20px">
            <el-table
                    :data="tableData"
                    @sort-change='pageOrder'
                    size="small"

                    :default-sort="{prop: 'opAt', order: 'descending'}"
                    style="width: 100%"
            >
                <el-table-column
                        sortable
                        prop="name"
                        label="角色名称"
                >
                </el-table-column>
                <el-table-column
                        prop="code"
                        label="角色标识"

                        :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column
                        prop="unit"
                        label="所属单位"

                        align="center"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                    <span v-if="scope.row.unit==null" style="font-weight: bold">
                        系统角色
                    </span>
                        <span v-if="scope.row.unit!=null">
                        {{scope.row.unit.name}}
                    </span>
                    </template>
                </el-table-column>
                <el-table-column
                        sortable
                        label="启用状态"
                        align="center"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <el-tag v-if="!scope.row.disabled">启用</el-tag>
                        <el-tag v-else type="danger">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        sortable
                        prop="opAt"
                        label="操作时间"
                        align="center">
                    <template slot-scope="scope">
                        {{formatAt(scope.row.opAt)}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="useronline"
                        label="操作"
                        width="120">
                    <template slot-scope="scope">
                        <el-dropdown @command="dropdownCommand">
                            <el-button size="mini" type="primary">
                                <i class="el-icon-setting"></i>
                                操作
                                <span class="el-icon-arrow-down"></span>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="{type:'role',id:scope.row.id,name:scope.row.name}">
                                    查看权限
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'menu',id:scope.row.id,name:scope.row.name}">
                                    分配权限
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'user',id:scope.row.id,name:scope.row.name}">
                                    分配用户
                                </el-dropdown-item>
                                <el-dropdown-item divided
                                                  :command="{type:'enable',id:scope.row.id,name:scope.row.name,row:scope.row}">
                                    启用
                                </el-dropdown-item>
                                <el-dropdown-item
                                        :command="{type:'disable',id:scope.row.id,name:scope.row.name,row:scope.row}">
                                    禁用
                                </el-dropdown-item>
                                <el-dropdown-item divided :command="{type:'edit',id:scope.row.id}">
                                    修改
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'delete',id:scope.row.id,name:scope.row.name}"
                                                  v-if="scope.row.code!='superadmin'&&scope.row.code!='public'">
                                    删除
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    @size-change="pageSizeChange"
                    @current-change="pageNumberChange"
                    :current-page="pageForm.pageNumber"
                    :page-sizes="[10, 20, 30, 50]"
                    :page-size="pageForm.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageForm.totalCount">
            </el-pagination>
        </el-main>
        <form-dialog @close="handleClose('addForm')" title="新建角色" :dialogVisible="addDialogVisible">
            <template slot="content">
                <el-form :model="formData" ref="addForm" :rules="formRules" size="small" label-width="80px">
                    <el-form-item class="is-required" prop="parentUnit" label="所属单位" label-width="80px">
                        <el-cascader :props="props" v-model="parentUnit" placeholder="请选择单位"
                                     style="width: 100%"></el-cascader>

                    </el-form-item>
                    <el-form-item prop="name" label="角色名称">
                        <el-input maxlength="50" placeholder="角色名称"
                                  v-model="formData.name"
                                  auto-complete="off" tabindex="2" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="code" label="角色标识">
                        <el-input maxlength="150" placeholder="角色标识"
                                  v-model="formData.code"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="disabled" label="启用状态">
                        <el-switch
                                v-model="formData.disabled"
                                active-color="#ff4949"
                                inactive-color="#13ce66">
                        </el-switch>
                    </el-form-item>
                    <el-form-item prop="" label="分配权限">
                        <el-row style="margin-bottom: 3px;">
                            <el-button size="small" @click="addRoleSelAll">全选</el-button>
                            <el-button size="small" @click="addRoleSelClear">清空</el-button>
                        </el-row>
                        <el-tree
                                ref="addMenuTree"
                                :data="addMenuData"
                                show-checkbox

                                node-key="id"
                                :props="defaultProps"
                        >
                    <span class="custom-tree-node" slot-scope="scope">
                    <span>{{ scope.node.label }}</span>
                    <span>
                        <span v-if="scope.data.type=='menu'" style="font-weight: bold">菜单权限 </span>
                        <span v-if="scope.data.type=='data'">数据权限 </span>
                    </span>
                  </span>
                        </el-tree>

                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button @click="addDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="doAdd">确 定</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
        <form-dialog @close="handleClose('editForm')" title="修改角色" :dialogVisible="editDialogVisible">
            <template slot="content">
                <el-form :model="formData" ref="editForm" :rules="formRules" size="small" label-width="80px">
                    <el-form-item prop="name" label="角色名称">
                        <el-input maxlength="50" placeholder="角色名称"
                                  v-model="formData.name"
                                  auto-complete="off" tabindex="2" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="code" label="角色标识">
                        <el-input maxlength="150" placeholder="角色标识"
                                  v-model="formData.code"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="disabled" label="启用状态">
                        <el-switch
                                v-model="formData.disabled"
                                active-color="#ff4949"
                                inactive-color="#13ce66">
                        </el-switch>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button @click="editDialogVisible = false">取 消</el-button>
                        <el-button type="primary" @click="doEdit">确 定</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
        <form-dialog @close="handleClose()" :title="roleDialogTitle" :dialogVisible="roleDialogVisible" width="60%">
            <template slot="content">
                <el-table border :data="roleTableData" style="width: 100%" size="small"
                          :highlight-current-row="true" row-key="id" lazy
                          :load="loadRoleChild">
                    <el-table-column label="菜单名称" prop="name"
                                     width="200" :show-overflow-tooltip="true" align="left">

                    </el-table-column>

                    <el-table-column label="URL" prop="href"
                                     :show-overflow-tooltip="true">
                    </el-table-column>

                    <el-table-column label="资源类型" prop="type"
                                     :show-overflow-tooltip="true" align="center">

                        <template slot-scope="scope">
                            <i v-if="scope.row.type=='menu'" style="font-weight: bold">菜单</i>
                            <i v-if="scope.row.type=='data'">数据</i>
                        </template>
                    </el-table-column>

                    <el-table-column label="权限标识" prop="permission"
                                     :show-overflow-tooltip="true">
                    </el-table-column>

                </el-table>
                <br>
                <div class="flex flex-direction-row-reverse btn-group">
                    <el-button @click="roleDialogVisible = false">关 闭</el-button>
                </div>
            </template>
        </form-dialog>
        <form-dialog @close="handleClose()" :title="menuDialogTitle" :dialogVisible="menuDialogVisible" width="60%">
            <template slot="content">
                <el-row style="margin-bottom: 3px;">
                    <el-button size="small" @click="menuRoleSelAll">全选</el-button>
                    <el-button size="small" @click="menuRoleSelClear">清空</el-button>
                </el-row>
                <el-tree
                        ref="doMenuTree"
                        :data="doMenuData"
                        :default-checked-keys="doMenuCheckedData"
                        :check-strictly="true"
                        show-checkbox
                        node-key="id"
                        :props="defaultProps"
                >
                    <span class="custom-tree-node" slot-scope="scope">
                    <span>{{ scope.node.label }}</span>
                    <span>
                        <span v-if="scope.data.type=='menu'" style="font-weight: bold">菜单权限 </span>
                        <span v-if="scope.data.type=='data'">数据权限 </span>
                    </span>
                  </span>
                </el-tree>
                <div class="flex flex-direction-row-reverse btn-group">
                    <el-button @click="menuDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="doMenu">确 定</el-button>
                </div>
            </template>
        </form-dialog >
        <form-dialog @close="handleClose()" :title="userDialogTitle" :dialogVisible="userDialogVisible" width="60%">
            <template slot="content">
                <div class="block"
                     style="display: flex;align-items: center;margin-bottom: 10px;justify-content: space-between">
                    <el-select
                            size="small"
                            style="width: 60%"
                            class="span_n"
                            v-model="selUsers"
                            multiple
                            filterable
                            remote
                            default-first-option
                            reserve-keyword
                            :remote-method="remoteMethod"
                            :loading="loading"
                            placeholder="请输入用户名或姓名"
                    >
                        <el-option
                                v-for="item in dbUsers"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button size="small" @click="userAddRole">将用户加入角色</el-button>
                    <el-button size="small" type="danger" @click="userDelRole">从角色中移除</el-button>
                </div>
                <el-table
                        :data="userTableData"
                        @sort-change='userPageOrder'
                        size="small"
                        style="width: 100%"
                        @selection-change="userSelectionChange"
                >
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column
                            sortable
                            prop="loginname"
                            label="用户名"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="username"
                            label="姓名"
                            :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column
                            prop="unitname"
                            label="所属单位"
                            align="center"
                            :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column
                            sortable
                            label="帐号状态"
                            align="center"
                            :show-overflow-tooltip="true"
                    >
                        <template slot-scope="scope">
                            <el-tag v-if="!scope.row.disabled">启用</el-tag>
                            <el-tag v-else type="danger">禁用</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                            sortable
                            prop="useronline"
                            label="在线状态"

                            align="center"
                            :show-overflow-tooltip="true"
                    >
                        <template slot-scope="scope">
                            <i v-if="scope.row.useronline" class="text-success ml5">在线</i>
                            <i v-if="!scope.row.useronline" class="text-danger ml5">离线</i>
                        </template>
                    </el-table-column>
                </el-table>
                <br>
                <el-pagination
                        @size-change="userPageSizeChange"
                        @current-change="userPageNumberChange"
                        :current-page="userForm.pageNumber"
                        :page-sizes="[10, 20, 30, 50]"
                        :page-size="userForm.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="userForm.totalCount">
                </el-pagination>
                <div class="flex flex-direction-row-reverse btn-group">
                    <el-button @click="userDialogVisible = false">关 闭</el-button>
                </div>
            </template>
        </form-dialog>
    </el-container>

</template>

<script>
    import formDialog from '../../components/formDialog'
    import {
        getRoleData,
        menuAll,
        menuRole,
        userSearch,
        roleUser,
        roleAddDo,
        unitTree,
        roleMenuDo,
        roleMenu,
        roleType,
        roleEdit,
        roleDelete,
        roleEditDo,
        roleUsersAdd,
        rolesDelete
    } from '../../api/sysRole'
    import moment from 'moment'

    export default {
        name: "sysRole",
        components: {formDialog},
        data: function () {
            var validateName = (rule, value, callback) => {
                var regex = new RegExp("^[a-zA-Z][a-zA-Z0-9_]{1,49}$");
                if (!regex.test(value)) {
                    callback(new Error('角色标识以字母开头2-50字,允许使用字母/数字/下划线'));
                } else {
                    callback();
                }
            };
            var validateUnit = (rule, value, callback) => {
                if (this.parentUnit.length < 1 || this.parentUnit[this.parentUnit.length - 1] == "" || this.parentUnit[this.parentUnit.length - 1] == "root") {
                    callback(new Error('请选择所属单位!'));
                } else {
                    callback();
                }
            };
            return {
                addDialogVisible: false,
                editDialogVisible: false,
                roleDialogVisible: false,
                menuDialogVisible: false,
                userDialogVisible: false,
                treeData: {
                    label: 'name',
                    children: 'zones',
                    id: 'id',
                    isLeaf: 'leaf'
                },
                tableData: [],
                options: [],
                parentUnit: [],
                parentUnitSearch: [],
                pageForm: {
                    searchUnit: "",
                    searchName: "",
                    searchKeyword: "",
                    pageNumber: 1,
                    pageSize: 10,
                    totalCount: 0,
                    pageOrderName: "",
                    pageOrderBy: ""
                },
                formData: {
                    id: "",
                    unitid: "",
                    disabled: false,
                    menuIds: ""
                },
                formRules: {
                    parentUnit: [
                        {validator: validateUnit, trigger: ['blur', 'change']}
                    ],
                    name: [
                        {required: true, message: '角色名称', trigger: ['blur', 'change']}
                    ],
                    code: [
                        {required: true, message: '角色标识', trigger: ['blur', 'change']},
                        {validator: validateName, trigger: ['blur', 'change']}
                    ],
                },
                editRules: {
                    parentUnit: [
                        {validator: validateUnit, trigger: ['blur', 'change']}
                    ],
                    name: [
                        {required: true, message: '角色名称', trigger: ['blur', 'change']}
                    ],
                    code: [
                        {required: true, message: '角色标识', trigger: ['blur', 'change']},
                        {validator: validateName, trigger: ['blur', 'change']}
                    ],
                },
                roleDialogTitle: "查看角色权限",
                menuDialogTitle: "分配角色菜单",
                userDialogTitle: "分配用户",
                roleTableData: [],//treeTable后台取出的数据
                roleId: "",//当前角色ID
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                addMenuData: [],
                doMenuData: [],
                doMenuCheckedData: [],//已分配的权限选中状态
                userForm: {
                    roleId: "",
                    pageNumber: 1,
                    pageSize: 10,
                    totalCount: 0,
                    pageOrderName: "",
                    pageOrderBy: ""
                },
                userTableData: [],
                loading: false,
                dbUsers: [],//分配用户
                selUsers: [],//分配选中的用户
                userSelection: [],
                props: {
                    lazy: true,
                    checkStrictly: true,
                    multiple: false,
                    async lazyLoad(node, resolve) {
                        try {
                            let formData = {
                                pid: node.value
                            }
                            let {data} = await unitTree(formData)
                            resolve(data);
                        } catch (e) {

                        }
                    }
                },
                checkStrictly:true
            }
        },
        methods: {
            handleClose(form) {
                if (form) {
                    this.$refs[form].resetFields();
                }
                this.addDialogVisible = false
                this.editDialogVisible = false
                this.roleDialogVisible = false
                this.menuDialogVisible = false
                this.userDialogVisible = false
            },
            pageOrder: function (column) {//按字段排序
                this.pageForm.pageOrderName = column.prop;
                this.pageForm.pageOrderBy = column.order;
                this.pageData();
            },
            pageNumberChange: function (val) {//页码更新操作
                this.pageForm.pageNumber = val;
                this.pageData();
            },
            pageSizeChange: function (val) {//分页大小更新操作
                this.pageForm.pageSize = val;
                this.pageData();
            },
            async pageData() {//加载分页数据
                try {
                    let {data} = await getRoleData(this.pageForm)
                    this.tableData = data.list;
                    this.pageForm.totalCount = data.totalCount;
                } catch (e) {

                }
            },
            formatAt: function (val) {
                if (val > 0)
                    return moment(val * 1000).format("YYYY-MM-DD HH:mm");
                return "";
            },
            doSearch: function () {
                var unit = this.parentUnitSearch[this.parentUnitSearch.length - 1];
                if ("root" == unit) {
                    unit = "";
                }
                this.pageForm.searchUnit = unit;
                this.pageForm.pageNumber = 1;
                this.pageData();
            },
            async addMenuLoad() {
                try {
                    let {data} = await menuAll()
                    this.addMenuData = data;
                } catch (e) {

                }
            },
            async doMenuLoad() {
                try {
                    // this.checkStrictly = true
                    let {data} = await menuRole(this.roleId)
                    this.doMenuData = data.menu;
                    this.doMenuCheckedData = data.cmenu;
                    // this.$nextTick(()=>{
                    //     this.checkStrictly = false
                    // })
                } catch (e) {

                }
            },
            userSelectionChange: function (val) {
                this.userSelection = val;
            },
            userPageOrder: function (column) {//按字段排序
                this.userForm.pageOrderName = column.prop;
                this.userForm.pageOrderBy = column.order;
                this.doUserLoad();
            },
            userPageNumberChange: function (val) {//页码更新操作
                this.userForm.pageNumber = val;
                this.doUserLoad();
            },
            userPageSizeChange: function (val) {//分页大小更新操作
                this.userForm.pageSize = val;
                this.doUserLoad();
            },
            async remoteMethod(query) {
                try {
                    this.loading = true;
                    let formData = {
                        query: query,
                        roleId: this.roleId
                    }
                    let {data} = await userSearch(formData)
                    this.dbUsers = data.list;
                    this.loading = false;
                } catch (e) {

                }
            },
            async doUserLoad() {
                try {
                    let formData = this.userForm
                    let {data} = await roleUser(formData)
                    this.userTableData = data.list;
                    this.userForm.totalCount = data.totalCount;
                } catch (e) {
                    console.error(e)
                }
            },
            openAdd: function () {
                this.addDialogVisible = true;
                this.formData = {};//打开新增窗口,表单先清空
                if (this.$refs["addForm"])
                    this.$refs["addForm"].resetFields();
                this.addMenuLoad();
            },
            doAdd() {

                this.$refs["addForm"].validate(async (valid) => {
                    if (valid) {
                        this.formData.menuIds = (this.$refs["addMenuTree"].getCheckedKeys()).toString();
                        this.formData.unitid = this.parentUnit[this.parentUnit.length - 1] || "";
                        try {
                            let data = await roleAddDo(this.formData)
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                            this.parentUnitSearch = [];
                            this.addDialogVisible = false;
                            this.doSearch();
                        } catch (e) {

                        }
                    }
                });
            },
            doEdit: function () {
                this.$refs["editForm"].validate(async (valid) => {
                    if (valid) {
                        try {
                            let data = await roleEditDo(this.formData)
                            this.$message({
                                message: data.msg,
                                type: 'success'
                            });
                            this.doSearch();//查询是带条件的,加载新建用户选择的单位用户数据
                            //this.pageData();
                            this.editDialogVisible = false;
                        } catch (e) {

                        }
                    }
                });
            },
            async doMenu() {

                var ids = this.$refs["doMenuTree"].getCheckedKeys();
                if (!ids || ids.length == 0) {
                    this.$message({
                        message: "请选择菜单或数据权限",
                        type: 'warning'
                    });
                    return;
                }
                try {
                    let formData = {
                        roleId: this.roleId,
                        menuIds: ids.toString()
                    }
                    let data = await roleMenuDo(formData)
                    this.$message({
                        message: data.msg,
                        type: 'success'
                    });
                    this.menuDialogVisible = false;
                } catch (e) {

                }
            },
            async initRoleTreeTable() {
                try {
                    let {data} = await roleMenu(this.roleId)
                    this.roleTableData = data;
                } catch (e) {

                }
            },
            async loadRoleChild(tree, treeNode, resolve) {
                try {
                    let {data} = await roleMenu(tree.id)
                    resolve(data)
                } catch (e) {

                }
            },
            async dropdownCommand(command) {//监听下拉框事件
                if ("role" == command.type) {
                    this.roleDialogVisible = true;
                    this.roleDialogTitle = "查看角色 【" + command.name + "】 的权限";
                    this.roleId = command.id;
                    this.initRoleTreeTable();
                }
                if ("menu" == command.type) {
                    this.menuDialogTitle = "分配角色 【" + command.name + "】 的权限";
                    this.menuDialogVisible = true;
                    this.roleId = command.id;
                    this.doMenuLoad();
                }
                if ("user" == command.type) {
                    this.userDialogTitle = "分配角色 【" + command.name + "】 的用户";
                    this.userDialogVisible = true;
                    this.roleId = command.id;
                    this.userForm.roleId = command.id;
                    this.remoteMethod('');
                    this.doUserLoad();
                }
                if ("enable" == command.type || "disable" == command.type) {
                    try {
                        let data = await roleType(command.type, command.id)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        if ("disable" == command.type) {
                            command.row.disabled = true;
                        }
                        if ("enable" == command.type) {
                            command.row.disabled = false;
                        }
                    } catch (e) {

                    }
                }
                if ("edit" == command.type) {

                    try {
                        let {data} = await roleEdit(command.id)
                        this.formData = data;//加载后台表单数据
                        this.editDialogVisible = true;//打开编辑窗口
                    } catch (e) {

                    }

                }
                if ("delete" == command.type) {
                    try {
                        await this.$confirm('此操作将删除 ' + command.name, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                        let data = await roleDelete(command.id)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.doSearch();
                    } catch (e) {

                    }
                }
            },
            async userAddRole() {

                if (this.selUsers.length == 0) {
                    this.$message({
                        message: "请先输入用户名或姓名查询用户",
                        type: 'warning'
                    });
                    return;
                }
                try {
                    let formData = {
                        roleId: this.roleId,
                        users: this.selUsers.toString()
                    }
                    let data = await roleUsersAdd(formData)
                    this.$message({
                        message: data.msg,
                        type: 'success'
                    });
                    this.selUsers = [];//清除已选择的用户
                    this.dbUsers = [];//清除数据库查询的用户,已添加到角色的用户不应该还存在so..
                    this.remoteMethod('');
                    this.doUserLoad();
                } catch (e) {

                }
            },
            async userDelRole() {
                if (this.userSelection.length == 0) {
                    this.$message({
                        message: "请先选择要移除的用户",
                        type: 'warning'
                    });
                    return;
                }
                var users = [];
                this.userSelection.forEach(function (obj) {
                    users.push(obj.id);
                });
                let formData = {
                    roleId: this.roleId,
                    users: users.toString()
                }
                let data = await rolesDelete(formData)
                this.$message({
                    message: data.msg,
                    type: 'success'
                });
                this.selUsers = [];//清除已选择的用户
                this.dbUsers = [];//清除数据库查询的用户,已移除的用户应该能重新被查询到..
                this.remoteMethod('');
                this.doUserLoad();
            },
            getTreeAllIds(ids, obj) {
                if (obj && obj.length > 0) {
                    obj.forEach((o) => {
                        ids.push(o.id);
                        if (o.children) {
                            this.getTreeAllIds(ids, o.children);
                        }
                    });
                }
            },
            addRoleSelAll() {
                var ids = [];
                this.getTreeAllIds(ids, this.addMenuData);
                this.$refs["addMenuTree"].setCheckedKeys(ids);
            },
            addRoleSelClear: function () {
                this.$refs["addMenuTree"].setCheckedKeys([]);
            },
            menuRoleSelAll() {
                var ids = [];
                this.getTreeAllIds(ids, this.doMenuData);
                this.$refs["doMenuTree"].setCheckedKeys(ids);
            },
            menuRoleSelClear: function () {
                this.$refs["doMenuTree"].setCheckedKeys([]);
            }
        },
        created: function () {
            this.pageData();
        }
    }
</script>

<style lang="less">
    .btn-group {
        button {
            margin-left: 20px;
        }

    }

    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }

    .el-input__prefix {
        left: 15px;
        color: #303133;
        i {
            font-style: normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348EED;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #DCDFE6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409EFF;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }

</style>

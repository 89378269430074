import request from '../utils/request'

//查询角色
export function getRoleData(data) {
    return request({
        url:'/platform/sys/role/data',
        method:'post',
        data
    })
}
//所有菜单
export function menuAll() {
    return request({
        url:'/platform/sys/role/menuAll',
        method:'post'
    })
}
//角色列表
export function menuRole(id) {
    return request({
        url:`/platform/sys/role/menuRole/${id}`,
        method:'post'
    })
}
//用户搜索
export function userSearch(data) {
    return request({
        url:'/platform/sys/role/userSearch',
        method:'post',
        data
    })
}
//用户查询

export function roleUser(data) {
    return request({
        url:'/platform/sys/role/user',
        method:'post',
        data
    })
}
//新增用户列表

export function roleAddDo(data) {
    return request({
        url:'/platform/sys/role/addDo',
        method:'post',
        data
    })
}

//编辑用户列表

export function roleEditDo(data) {
    return request({
        url:'/platform/sys/role/editDo',
        method:'post',
        data
    })
}
//编辑用户列表

export function roleMenuDo(data) {
    return request({
        url:'/platform/sys/role/menuDo',
        method:'post',
        data
    })
}

export function roleMenu(id) {
    return request({
        url:`/platform/sys/role/menu/${id}`,
        method:'post'
    })
}

export function roleType(type,id) {
    return request({
        url:`/platform/sys/role/${type}/${id}`,
        method:'post'
    })
}

export function roleEdit(id) {
    return request({
        url:`/platform/sys/role/edit/${id}`,
        method:'post'
    })
}
export function roleDelete(id) {
    return request({
        url:`/platform/sys/role/delete/${id}`,
        method:'post'
    })
}
export function rolesDelete(data) {
    return request({
        url:`/platform/sys/role/delete`,
        method:'post',
        data
    })
}
export function roleUsersAdd(data) {
    return request({
        url:`/platform/sys/role/usersAdd`,
        method:'post',
        data
    })
}
//角色树结构
export function unitTree(data) {
    return request({
        url:'/platform/sys/unit/tree',
        method:'post',
        data
    })
}
